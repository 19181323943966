import { LOGIN_FLAG_COOKIE_NAME } from './constants.js';
import { logger } from './logger.js';

const isLoggedIn = () => {
  const loginFlagCookie = document.cookie
    .split('; ')
    .find((row) => row.startsWith(LOGIN_FLAG_COOKIE_NAME));

  if (!loginFlagCookie) {
    return false;
  }

  const value = loginFlagCookie.split('=')[1];

  return value === '1';
};

/**
 * Pushes the external JWT into the tp object.
 * @param {string} token the JWT token
 */
const setExternalJWT = (token) => {
  tp.push(['setExternalJWT', token]);
  logger.debug('JWT sent to Piano', token);
};

export const setupAuth = async () => {
  if (!isLoggedIn()) {
    logger.debug('User is not logged in, not fetching id token.');
    return;
  }

  try {
    const response = await fetch('/auth/user-info?fields=idToken');

    if (!response.ok) {
      throw new Error('Failed to fetch user info');
    }

    const data = await response.json();
    const { idToken } = data;

    if (!idToken) {
      logger.error('No idToken found in user info data');
      return;
    }

    setExternalJWT(idToken);
  } catch (error) {
    logger.debug('Error fetching user info:', error);
  }
};

/**
 *
 * Redirects user to correct authentication journey.
 */
export const authRedirect = (endpoint) => {
  const authUrl = new URL(endpoint, window.location.origin);

  authUrl.searchParams.set('redirect', window.location.href);
  window.location.assign(authUrl.href);
};
