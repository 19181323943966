import { logger } from './logger.js';

/**
 * Listen for history events.
 *
 * Next.js, for example, will call history.pushState or history.replaceState
 * just before the route is changed. When that happens we begin polling for the
 * new URL.
 *
 * Note that we use this method rather than the Next.js router hooks so we can
 * keep this script Next.js and React agnostic. Ideally, we want to place this
 * script on the page and that's all we need to do to get Piano running,
 * regardless of the tech being used be any particular service.
 */
const addHistoryEventListener = (historyFunction, callback) => {
  const originalFunc = window.history[historyFunction];

  window.history[historyFunction] = (...args) => {
    const { pathname: previousPathname } = window.location;

    const timerId = setInterval(() => {
      const newPathname = window.location.pathname;

      if (previousPathname === newPathname) {
        return; // Skip if the path has not changed
      }

      logger.debug('Route change detected', {
        previousPathname,
        newPathname,
        historyFunction,
        callback,
      });

      callback();

      clearInterval(timerId);
    }, 500);

    originalFunc.apply(window.history, args);
  };
};

/**
 * Invoke a calback when a route changes.
 */
export const onRouteChange = (callback) => {
  addHistoryEventListener('pushState', callback);
  addHistoryEventListener('replaceState', callback);
};
