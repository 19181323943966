import { getSettings } from '@immediate_media/script-utils';
import { getOS, setCustomVariables } from './segmentation.js';
import {
  insertPianoScript,
  onDOMReady,
  addGtmDataFromPiano,
} from './dom.js';
import { addSpeedCurveLuxExperienceData } from './lux.js';
import { setupAuth } from './auth.js';
import { onRouteChange } from './router.js';
import { logger } from './logger.js';
import { mark } from './performance.js';
import {
  handleCheckoutClose,
  handleCheckoutComplete,
  handleCheckoutCustomEvent,
  handleCheckoutStateChange,
  handleInit,
  handleLoginRequired,
  handleStartCheckout,
  handleSubmitPayment,
} from './handlers/index.js';
import { CUSTOM_CONSENT_VARIABLE_NAME } from './consent.js';

window.tp = window.tp || [];

mark('time-to-piano-loaded');

// Initialise Piano consent management
window.pdl = window.pdl || {};
window.pdl.requireConsent = true;

/**
 * Initialise Piano.
 *
 * When DOM load has completed read the settings, initiate the insertion
 * of the Piano script, and push and required data.
 */
const init = () => {
  const settings = getSettings('pianoSettings') || {};
  const {
    enabled,
    applicationId,
    production,
    customVariables = [],
    composerSiteId,
  } = settings;

  logger.debug('Settings parsed', settings);

  if (!enabled) {
    return;
  }

  const state = {};
  const checkPianoExperienceReady = () => {
    Object.keys(state).forEach((expId) => {
      logger.debug('checkPianoExperienceReady', { state: state[expId] });
      const { iframeReady, experienceId } = state[expId];

      if (!iframeReady) return;

      const iframe = document.querySelector(`iframe[src*="${experienceId}"]`);

      if (!iframe) {
        logger.debug(`Can't find the piano iframe for experience ID: ${experienceId}`);

        return;
      }

      logger.debug(`iframe for ${experienceId} found`, { iframe });
      mark('time-to-piano-experience');

      addGtmDataFromPiano(iframe, state[expId]);
      addSpeedCurveLuxExperienceData(state[expId]);
    });
  };

  mark('time-to-piano-started');

  // This mechanism of setting the log level via localStorage is also used by
  // our logger package. Keep this at the top to help us debug any subsequent
  // calls. Note that it will only apply on sandbox mode and we should continue
  // adding our own logger.debug messages wherever it might be useful.
  tp.push(['setDebug', localStorage.logLevel === 'debug']);

  tp.push(['setAid', applicationId]);
  tp.push(['setSandbox', !production]);
  tp.push(['setUseTinypassAccounts', false]);
  tp.push(['setUsePianoIdUserProvider', false]);
  tp.push(['setUsePianoIdLiteUserProvider', true]);
  tp.push(['setCxenseSiteId', composerSiteId]);

  customVariables.push({ key: 'operatingSystem', value: getOS() });

  // Once the init handler runs and the user has accepted/rejected consent,
  // this custom variable will be set to `true`/`false`. `null` represents
  // the state where the user has not yet interacted with the CMP. For a
  // returning user, this `null` will be overwritten before experiences run.
  customVariables.push({ key: CUSTOM_CONSENT_VARIABLE_NAME, value: null });

  setCustomVariables(customVariables);

  insertPianoScript();

  // setAuth must stay above the init call
  setupAuth().then(() => {
    logger.debug('adding handlers');

    tp.push(['addHandler', 'loginRequired', handleLoginRequired]);

    tp.push(['addHandler', 'checkoutClose', handleCheckoutClose]);
    tp.push(['addHandler', 'checkoutComplete', handleCheckoutComplete]);
    tp.push(['addHandler', 'checkoutCustomEvent', handleCheckoutCustomEvent]);
    tp.push(['addHandler', 'checkoutStateChange', handleCheckoutStateChange]);
    tp.push(['addHandler', 'startCheckout', handleStartCheckout]);
    tp.push(['addHandler', 'submitPayment', handleSubmitPayment]);

    tp.push(['init', handleInit]);

    // showTemplate might execute after experienceExecute!
    // The iframe always exists when the showTemplate callback executes
    tp.push(['addHandler', 'showTemplate', (templateObj) => {
      logger.debug('showTemplate', { templateObj });
      const { experienceId } = templateObj;
      state[experienceId] = {
        ...state[experienceId],
        iframeReady: true,
      };
      checkPianoExperienceReady();
    }]);

    // showOffer might execute after experienceExecute!
    // The iframe always exists when the showOffer callback executes
    tp.push(['addHandler', 'showOffer', (offerObj) => {
      logger.debug('showOffer', { offerObj });
      const { experienceId } = offerObj;
      state[experienceId] = {
        ...state[experienceId],
        iframeReady: true,
      };
      checkPianoExperienceReady();
    }]);

    tp.push(['addHandler', 'experienceExecute', (expObj) => {
      logger.debug('experienceExecute', { expObj });
      const { events, experiences } = expObj.result;
      events
        .filter((event) => ['showTemplate', 'showOffer'].includes(event.eventType))
        .forEach((event) => {
          const { experienceId } = event.eventExecutionContext;
          const {
            containerSelector,
            displayMode,
            templateId,
            templateVariantId: variantId,
          } = event.eventParams;
          const title = experiences
            .find((exp) => exp.id === experienceId)
            .title
            .split(' ')
            .join('-')
            .toLowerCase();

          logger.debug('process experienceExecute', { event, experiences });

          state[experienceId] = {
            ...state[experienceId],
            containerSelector,
            displayMode,
            title,
            experienceId,
            templateId,
            variantId,
          };
        });
      checkPianoExperienceReady();
    }]);

    // Watch for route changes for SPAs
    onRouteChange(() => {
      window.tp.experience?.execute();
    });
  });
};

onDOMReady(() => {
  init();
});
