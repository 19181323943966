/**
 * Recursively removes all null/undefined properties from an object
 * @param {Record<string, any>} input An object
 */
export const removeEmptyProperties = (input) => {
  // When mapping over values in an array, this ensures that we don't invoke
  // Object.entries with a primitive value/null/undefined
  if (typeof input !== 'object' || input === null) {
    return input;
  }

  // For mapping over deeply nested arrays
  if (Array.isArray(input)) {
    return input.map(removeEmptyProperties).filter(Boolean);
  }

  return Object.entries(input).reduce((acc, [key, value]) => {
    if (value === null || value === undefined) {
      return acc;
    }

    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        return {
          ...acc,
          [key]: value.map(removeEmptyProperties).filter(Boolean),
        };
      }

      return {
        ...acc,
        [key]: removeEmptyProperties(value),
      };
    }

    return {
      ...acc,
      [key]: value,
    };
  }, {});
};
