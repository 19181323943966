import { checkPurposes, waitForConsentChange } from '@immediate_media/consent-checker';
import {
  ALL_PRODUCTS, RELEVANT_PRODUCTS, REQUIRED_PURPOSES, REQUIRED_VENDOR_ID,
} from './constants.js';
import { logger } from './logger.js';
import { addSpeedCurveLuxPianoConsentData } from './lux.js';
import { insertPianoAdblockDetectorScript } from './dom.js';
import { setCustomVariables } from './segmentation.js';

export const CUSTOM_CONSENT_VARIABLE_NAME = 'consentOptIn';

/**
 * Set product consent modes in the Piano consent management platform.
 * @param {Array<string>} products The products to set
 * @param {'opt-in' | 'opt-out'} mode The mode to set
 */
export const setPianoProductConsent = (products, mode) => {
  if (typeof tp?.consent?.set !== 'function') {
    logger.error(`Attempted to set Piano consent but tp.consent.set was ${tp?.consent?.set}.`);
    return;
  }

  products.forEach((product) => tp.consent.set(product, { mode }));
};

/**
 * Add a custom variable to the `tp` object that can be used in experiences/tests
 * to determine whether the user has interacted/accepted/rejected the CMP.
 * @param {boolean} optIn
 */
const setCustomConsentVariable = (optIn) => {
  setCustomVariables([{ key: CUSTOM_CONSENT_VARIABLE_NAME, value: optIn }]);
};

/**
 * Returns `true` if some of the product modes obtained from tp.consent.get()
 * are set to `'opt-in'`. Otherwise, returns `false`.
 */
export const isPianoConsentOptIn = () => {
  if (typeof tp?.consent?.get !== 'function') {
    logger.error(`Attempted to get Piano consent but tp.consent.get was ${tp?.consent?.get}.`);
    return false;
  }

  const pianoConsent = tp.consent.get();

  if (!pianoConsent) {
    return false;
  }

  return Object.values(pianoConsent).some(({ mode }) => mode === 'opt-in');
};

/**
 * Returns `true` where:
 * - The user has not yet given consent in the CMP
 * - The user has rejected consent in the CMP
 * - There has been an error retrieving user consent
 * - The user has not accepted all of the required purposes
 */
export const isCmpRejected = () => !checkPurposes(REQUIRED_PURPOSES, REQUIRED_VENDOR_ID);

/**
 * Synchronises Piano consent with CMP consent on initialisation.
 *
 * Unless Piano product consent mode is already `'opt-in'`, this will
 * also add a listener for consent change events in relation to the CMP
 * to update Piano product consent after a user accepts/rejects CMP consent.
 */
export const syncPianoConsent = () => {
  // If cookie consent has not (or not yet) been given, set all product consent values to
  // `opt-out`. This also keeps Piano consent in sync with CMP consent in the event that
  // the user changes their CMP consent without removing Piano's __pprv cookie.
  if (isCmpRejected()) {
    setPianoProductConsent(ALL_PRODUCTS, 'opt-out');
  }

  // The only way Piano product consent could be opt-in is if we have previously gone
  // through the CMP consent flow with the user. We can therefore mark the user as
  // opted in and bail early.
  if (isPianoConsentOptIn()) {
    setCustomConsentVariable(true);
    addSpeedCurveLuxPianoConsentData({ optIn: true });
    insertPianoAdblockDetectorScript();

    return;
  }

  let eventCount = 0;

  waitForConsentChange(() => {
    eventCount += 1;

    const isRejected = isCmpRejected();

    if (isRejected) {
      logger.debug('CMP rejected', { eventCount });

      // The first consent change event is when the CMP loads. At that point in time, the user
      // will be reported as not having consented, but we want to wait until the second event
      // (which is when the user actually chooses their consent) before telling SpeedCurve that
      // they have opted out as only after the second event do we know their final consent status.
      if (eventCount === 2) {
        setCustomConsentVariable(false);
        addSpeedCurveLuxPianoConsentData({ optIn: false });
      }

      return;
    }

    logger.debug('CMP accepted', { eventCount });

    setCustomConsentVariable(true);

    // Update the specific products required by IM with `opt-in` consent
    // All other products will remain as `opt-out` as initialised above.
    setPianoProductConsent(RELEVANT_PRODUCTS, 'opt-in');
    addSpeedCurveLuxPianoConsentData({ optIn: true });
    insertPianoAdblockDetectorScript();

    // Re-run experiences to execute any with user segmentation reliant on `opt-in`
    tp.experience.execute();
  });
};
