export const getOS = () => {
  const userAgent = window?.navigator?.userAgent?.toLowerCase() || '';
  const { maxTouchPoints } = window?.navigator || {};

  if (userAgent.includes('win')) {
    return 'Windows';
  }

  if (userAgent.includes('android')) {
    return 'Android';
  }

  if (['iphone', 'ipad'].find((device) => userAgent.includes(device))) {
    return 'iOS';
  }

  if (userAgent.includes('mac')) {
    // Detection for iPads running iPadOS - Safari userAgent includes 'Macintosh'
    // therefore fallback to maxTouchPoints available to indicate a touch device
    // https://stackoverflow.com/questions/56578799/tell-ipados-from-macos-on-the-web
    return (maxTouchPoints && maxTouchPoints > 2) ? 'iOS' : 'macOS';
  }

  return null;
};

/**
 * Push custom variables to piano.
 */
export const setCustomVariables = (customVariables = []) => {
  customVariables.forEach(({ key, value }) => {
    if (key === 'tags') {
      tp.push(['setTags', value]);
    } else {
      tp.push(['setCustomVariable', key, value]);
    }
  });
};
