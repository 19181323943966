import { REGISTER_ROUTE } from '../constants.js';
import { saveCheckout } from '../checkout.js';
import { authRedirect } from '../auth.js';
import { logger } from '../logger.js';

export const handleLoginRequired = (checkoutParams) => {
  logger.debug('loginRequired', { checkoutParams });

  saveCheckout(checkoutParams);

  // Redirect to registration page because users are more likely
  // to not be registered when they try to access premium content.
  // See: https://immediateco.atlassian.net/browse/FAB-35673
  authRedirect(REGISTER_ROUTE);

  return false;
};
