import { resumeCheckout } from '../checkout.js';
import { mark } from '../performance.js';
import { logger } from '../logger.js';
import { syncPianoConsent } from '../consent.js';

export const handleInit = () => {
  mark('time-to-piano-initialised');
  logger.debug('init');

  syncPianoConsent();

  tp.experience.init();

  resumeCheckout();
};
