/**
 * Push a GA4 event to the data layer
 * @param {string} event The event name
 * @param {object?} data The event parameters
 */
export const pushEventToDataLayer = (event, data = {}) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event,
    ...data,
  });
};
