import { logger } from './logger.js';

/**
 * Insert the Piano script into the document head.
 */
export const insertPianoScript = () => {
  const { head } = document;
  const script = document.createElement('script');

  script.src = '//cdn.tinypass.com/api/tinypass.min.js';
  script.async = true;

  head.appendChild(script);
};

/**
 * Returns `true` if the Piano adblock script is in the DOM.
 * @returns {boolean} `true` if the Piano adblock detector script is in the DOM.
 */
export const isPianoAdblockDetectorScriptInDOM = () => {
  const scripts = Array.from(document.querySelectorAll('script'));
  return scripts.some((script) => script.getAttribute('src') === '//www.npttech.com/advertising.js');
};

/**
 * Insert the Piano adbocker detector script into the document head.
 */
export const insertPianoAdblockDetectorScript = () => {
  if (isPianoAdblockDetectorScriptInDOM()) {
    return;
  }

  logger.debug('Inserting Piano adblock script');

  const adblockDetector = `
    document.cookie = "__adblocker=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
    var setNptTechAdblockerCookie = function(adblocker) {
      var d = new Date();
      d.setTime(d.getTime() + 60 * 5 * 1000);
      document.cookie = "__adblocker=" + (adblocker ? "true" : "false") + "; expires=" + d.toUTCString() + "; path=/";
    };
    var script = document.createElement("script");
    script.setAttribute("async", true);
    script.setAttribute("src", "//www.npttech.com/advertising.js");
    script.setAttribute("onerror", "setNptTechAdblockerCookie(true);");
    document.getElementsByTagName("head")[0].appendChild(script);
  `;
  const { head } = document;
  const script = document.createElement('script');
  script.textContent = adblockDetector;
  head.appendChild(script);
};

/**
 * Invoke a callback when the DOM is ready.
 */
export const onDOMReady = (callback) => {
  if (document.readyState === 'interactive' || document.readyState === 'complete') {
    callback();
  } else {
    document.addEventListener('DOMContentLoaded', callback);
  }
};

/**
 * Adds gtm attributes including class, experience title, experience ID,
 * template ID, template variant ID as data attribute to given element.
 */
export const addGtmDataFromPiano = (iframe, {
  containerSelector,
  displayMode,
  title,
  experienceId,
  templateId,
  variantId,
}) => {
  if (containerSelector) {
    iframe.setAttribute('data-placement', containerSelector);
  }

  if (variantId) {
    iframe.setAttribute('data-piano-variant-id', variantId);
  }

  iframe.setAttribute('data-feature', 'Piano');
  iframe.setAttribute('data-piano-template-id', templateId);
  iframe.setAttribute('data-piano-experience-name', title);
  iframe.setAttribute('data-piano-experience-id', experienceId);
  iframe.setAttribute('data-placement-variant', displayMode);
};
