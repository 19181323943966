import { pianoCheckoutParams } from './constants.js';
import { logger } from './logger.js';

/**
 * Store Checkout data in localStorage for retrieval after log in.
 *
 * Saves checkout data until user is authorized. Facilitates in resuming the checkout
 * flow from where user left off
 */
export const saveCheckout = (params) => {
  const checkoutParamsData = JSON.stringify(params);

  window.localStorage.setItem(pianoCheckoutParams, checkoutParamsData);
};

/**
 * Resume Checkout.
 *
 * Resume checkout process after user logs in and is redirected back to the same page
 * where she initiated the checkout process.
 */
export const resumeCheckout = () => {
  let params;

  try {
    params = JSON.parse(window.localStorage.getItem(pianoCheckoutParams));
  } catch (err) {
    logger.error('Couldn\'t parse checkout params');
  }

  if (params) {
    // Only resume checkout if the user is logged in to avoid getting stuck in a loop
    // where starting checkout continuously redirects an unauthenticated user back to
    // the register/login page.
    if (tp.pianoId.isUserValid()) {
      logger.debug('Resuming checkout with params: ', params);
      tp.offer.startCheckout(params);
    }

    window.localStorage.removeItem(pianoCheckoutParams);
  }
};
