/**
 * A proxy for our logger package.
 *
 * This saves adding the logger package as a dependency and drastically
 * increasing the bundle size, but means we can continue getting the benefits of
 * structured logging.
 */
const handler = {
  get(target, property) {
    const loggerName = 'piano';

    return (...args) => {
      window.IM = window.IM || {};
      window.IM.logger = window.IM.logger || { queue: [] };

      // If a logger has already been assigned to `target` (see below) and it
      // contains the given function (e.g. `log()`) then call it
      if (target[property]) {
        target[property](...args);
      }

      // Otherwise queue up the logging function
      window.IM.logger.queue.push(() => {
        const logger = window.IM.logger.withName(loggerName);

        // Assign the new logger to `target` to save creating it next time
        Object.assign(target, logger);

        // Call the logger function
        logger[property](...args);
      });
    };
  },
};

export const logger = typeof Proxy === 'undefined' || process.env.NODE_ENV === 'test'
  ? console // IE/testing fallback
  : new Proxy({}, handler);
