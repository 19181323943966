import { logger } from './logger.js';

/**
 * Utility function for sending custom data to Speedcurve
 * to support segmentation of RUM performance data.
 * @param {Record<string, string | number | boolean | null | undefined>} data
 * Key/value pairs which will be iterated over and sent to Speedcurve
 * @example
 * // Will translate to window.LUX.addData('hello', 'world'); *
 * addSpeedCurveLuxData({ hello: 'world' })
 * @see {@link https://support.speedcurve.com/docs/rum-js-api#luxadddataname-value}
 */
export const addSpeedCurveLuxData = (data) => {
  if (!window.LUX) {
    logger.warn(`Could not add SpeedCurve LUX data as window.LUX is ${window.LUX}`);

    return;
  }

  Object.entries(data).forEach(([key, value]) => window.LUX.addData(key, value));
};

/**
 * Add data to SpeedCurve LUX for a specific experience.
 * @param {{
 *  title: string;
 *  experienceId: string;
 *  templateId: string;
 *  variantId: string;
 * }} data The data relating to a given experience
 */
export const addSpeedCurveLuxExperienceData = ({
  title,
  experienceId,
  templateId,
  variantId,
}) => {
  addSpeedCurveLuxData({
    'piano-active': 'Yes',
    'piano-title': title,
    'piano-experience': experienceId,
    'piano-template': templateId,
    'piano-variant': variantId,
  });
};

/**
 * Add data to SpeedCurve to track whether a user has opted in to Piano product consent.
 * @param {{ optIn: boolean }} data Whether the user has opted in
 */
export const addSpeedCurveLuxPianoConsentData = ({ optIn }) => {
  addSpeedCurveLuxData({ piano_consent_opt_in: optIn });
};
