import { LOGIN_ROUTE, REGISTER_ROUTE } from '../constants.js';
import { authRedirect } from '../auth.js';
import { logger } from '../logger.js';
import {
  createTimestamp,
  pushEventToDataLayer,
  removeEmptyProperties,
} from '../utils/index.js';

/**
 * Send custom event to the dataLayer.
 * If event is triggered by a register/sign in button,
 * redirect the user to the relevant auth url.
 *
 * `event.params.templateParams` will be the params object from Piano:
 * @see https://docs.piano.io/offer-template-essentials/#externalevent
 * @param {{
 *   eventName: string | undefined;
 *   params?: {
 *     component?: string;
 *     componentVariant?: string;
 *     templateParams?: string;
 *     termId?: string;
 *   }
 * }} event The checkout custom event object. The properties are derived
 * from the `external-event` attribute in the Piano template (for `event.eventName`)
 * and `external-event-*` attributes (for properties in the `event.params` object)
 */
export const handleCheckoutCustomEvent = (event) => {
  // camelCase properties here are kebab-case attributes in the templates, i.e.
  // `params.componentVariant` comes from the DOM attribute `external-event-component-variant`
  const {
    component = 'button',
    componentVariant,
    termId,
  } = event?.params || {};

  const dlEventData = {
    piano: {
      'data-feature': 'Piano',
      'data-component': component,
      'data-component-variant': componentVariant,
      'data-piano-term-id': termId,
    },
  };

  try {
    // As above, the attribute name for `params.templateParams` is `external-event-template-params`
    const {
      experienceId,
      templateId,
      templateVariantId,
      displayMode: placementVariant,
    } = JSON.parse(event?.params?.templateParams || '{}');

    dlEventData.piano = {
      ...dlEventData.piano,
      'data-piano-experience-id': experienceId,
      'data-piano-template-id': templateId,
      'data-piano-template-variant-id': templateVariantId,
      'data-placement-variant': placementVariant,
    };
  } catch (error) {
    logger.error('Unable to parse Piano template params object', error);
  }

  pushEventToDataLayer('piano-cta-click', removeEmptyProperties(dlEventData));

  // Manage authentication redirects
  const authEventRedirects = {
    'register-button': REGISTER_ROUTE,
    'sign-in-link': LOGIN_ROUTE,
  };

  const { eventName } = event || {};

  if (Object.keys(authEventRedirects).includes(eventName)) {
    authRedirect(authEventRedirects[eventName]);
  }
};

/**
 * Push 'begin_checkout' event to data layer
 * @param {{ termId: string }} event Piano event data
 */
export const handleStartCheckout = ({ termId } = {}) => {
  pushEventToDataLayer('begin_checkout', {
    items: [
      { item_id: termId },
    ],
  });
};

/**
 * Push 'purchase' event to the data layer
 * @param {{
 *  chargeAmount: number;
 *  chargeCurrency: string;
 *  promotionId?: string;
 *  termId: string;
 *  uid: string;
 * }} event Piano event data
 */
export const handleCheckoutComplete = ({
  chargeAmount,
  chargeCurrency,
  promotionId,
  termId,
  uid,
} = {}) => {
  const transactionId = createTimestamp().concat(uid?.slice(0, 10));

  pushEventToDataLayer('purchase', removeEmptyProperties({
    coupon: promotionId,
    currency: chargeCurrency,
    transaction_id: transactionId,
    value: chargeAmount,
    items: [
      {
        item_id: termId,
        coupon: promotionId,
        currency: chargeCurrency,
        price: chargeAmount,
      },
    ],
  }));
};

/**
 * Redirect the user to the origin page upon checkout completion.
 * Push 'abandon_checkout' event to the data layer when user doesn't make a purchase.
 * @param {{
 *   state: 'close' | 'checkoutCompleted' | 'alreadyHasAccess' | 'voucherRedemptionCompleted'
 * }} event
 */
export const handleCheckoutClose = (event) => {
  if (event?.state === 'close') {
    // Piano incorrectly reports state as close when a user with access clicks the X icon
    // to close the checkout modal. We therfore do an extra check based on the result of
    // the handleCheckoutStateChange function below.
    if (!tp.customVariables?.alreadyHasAccess) {
      pushEventToDataLayer('abandon_checkout');
    }
  }

  if (['checkoutCompleted', 'alreadyHasAccess'].includes(event?.state)) {
    const urlParams = new URLSearchParams(window.location.search);
    const redirectUrl = urlParams.get('clientRedirect');

    if (!redirectUrl) {
      window.location.reload();
      return;
    }

    window.location.assign(redirectUrl);
  }
};

/**
 * Push `add_payment_info` event to data layer
 * @param {{
*   term?: {
  *     chargeAmount: number;
  *     chargeCurrency: string;
  *     termId: string;
  *     originalBillingPlan: {
  *       chargeAmount: number;
  *     } | null;
  *   }
  * }} event The `submitPayment` event data
  */
export const handleSubmitPayment = ({
  term: {
    chargeAmount: amountPaid,
    chargeCurrency: currency,
    originalBillingPlan,
    termId,
  },
} = { term: {} }) => {
  // If a promo code is used, term.chargeAmount will include any discount.
  // When a promo code is used, the `originalBillingPlan` object contains
  // a `chargeAmount` property, which is the price WITHOUT any discounts.
  // If no promo code is used, originalBillingPlan will be `null`
  const chargeWithPromoCode = originalBillingPlan?.chargeAmount;
  const discount = chargeWithPromoCode
    ? Number((chargeWithPromoCode - amountPaid).toFixed(2))
    : null;

  pushEventToDataLayer('add_payment_info', removeEmptyProperties({
    currency,
    value: amountPaid,
    items: [
      {
        currency,
        discount,
        item_id: termId,
        price: chargeWithPromoCode ?? amountPaid,
      },
    ],
  }));
};

/*
 * Workaround so we can check if a user alreadyHasAccess when closing the checkout using the X icon
 * @param {{
 *   stateName: 'alreadyHasAccess' | string
 * }} event
 */
export const handleCheckoutStateChange = (event) => {
  if (event?.stateName === 'alreadyHasAccess') {
    tp.push(['setCustomVariable', 'alreadyHasAccess', true]);
  }
};
