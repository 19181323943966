export const pianoCheckoutParams = 'piano_checkout_params';

// Products as used in the Piano consent management solution
export const RELEVANT_PRODUCTS = ['COMPOSER', 'ID', 'VX'];
export const ALL_PRODUCTS = [
  ...RELEVANT_PRODUCTS,
  'DL',
  'DMP',
  'ESP',
  'PA',
  'Social Flow',
];

/**
 * The personal data processing purposes required by Piano to run in product `opt-in` mode
 * @see {@link https://iabeurope.eu/iab-europe-transparency-consent-framework-policies/#Appendix_A_Purposes_and_Features_Definitions | IAB Framework}
 */
export const REQUIRED_PURPOSES = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

/**
 * The vendor id that needs to be accepted in the CMP for Piano to run in product `opt-in` mode
 * @summary The Piano Software vendor ID is 412
 */
export const REQUIRED_VENDOR_ID = [412];

export const LOGIN_ROUTE = '/auth/login';
export const REGISTER_ROUTE = '/auth/register';
export const LOGIN_FLAG_COOKIE_NAME = 'IMOLOGIN';
